import React, {useEffect, useState} from 'react';
import Exercises from "../components/db/EXERCISE.json"
import {
    globalExerciseFilterEquipmentsArray,
    globalExerciseFilterMGArray, globalExerciseTypeCoolDown,
    globalExerciseTypeWarmUp, globalMuscleGroupsArray
} from "./shared/Constants";
import MuscleGroupFilterItem from "./MuscleGroupFilterItem";
import EquipmentFilterItem from "./EquipmentFilterItem";
import ExerciseSearchResultSection from "./ExerciseSearch/ExerciseSearchResultSection";

function ExerciseSearch({targetSection, targetIndex, programJson, setProgramJson, selectedPhaseIndex, selectedWeekIndex, selectedDayIndex, addToUndoStack, deepCopy, exerciseMap}) {

    const [searchText, setSearchText] = useState("");
    const [filteredExercises, setFilteredExercises] = useState([]);
    const [filteredExercisesSections, setFilteredExercisesSections] = useState([[]]);
    const [selectedMuscleGroups, setSelectedMuscleGroups] = useState([0]);
    const [selectedEquipments, setSelectedEquipments] = useState([0]);
    const [selectedExercise, setSelectedExercise] = useState(null);

    useEffect(() => {
        displaySearchedRows();
    }, [searchText, selectedMuscleGroups, selectedEquipments])

    useEffect(() => {
        if (selectedExercise !== null){
            let originalProgramJson = deepCopy({...programJson});
            console.log("selected: ", selectedExercise);
            console.log("targetSection: " + targetSection);
            console.log("targetIndex: " + targetIndex);

            let originalPArray = JSON.parse(JSON.stringify([...programJson.p]));
            let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];

            newWArray[targetSection]["e"][targetIndex] = {...newWArray[targetSection]["e"][targetIndex],
                i : selectedExercise["ID"],
                mg: globalMuscleGroupsArray.indexOf(selectedExercise["MUSCLE_GROUP"])
            };
            console.log("programJson:");
            console.log(programJson);
            // programJson.w[targetSection]["e"][targetIndex]["i"]=selectedExerciseID;
            console.log("newWArray:");
            console.log(newWArray);

            originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
            addToUndoStack(originalProgramJson);
            setProgramJson({...programJson, p: originalPArray});

            //hide Search Modal
            closeModal();
            //reset selectedExercise ID to 0
            setSelectedExercise(null);
        }
    }, [selectedExercise]);

    useEffect(() => {

        let tempFilteredExercisesSections = new Array(globalExerciseFilterMGArray.length);

        for (let i = 0; i < tempFilteredExercisesSections.length; i++){
            tempFilteredExercisesSections[i] = [];
        }

        for (let i = 0; i < filteredExercises.length; i++){
            const thisExercise = filteredExercises[i];

            // Handle the MUSCLE_GROUP field which might be a string or a stringified array
            let muscleGroups = [];
            if (thisExercise["MUSCLE_GROUP"].startsWith('[')) {
                // Parse the stringified array
                muscleGroups = JSON.parse(thisExercise["MUSCLE_GROUP"]);
            } else {
                // Treat as a single value array for consistency
                muscleGroups = [thisExercise["MUSCLE_GROUP"]];
            }

            if (thisExercise["TYPE"] === globalExerciseTypeWarmUp){
                // Add to the first section (Warm-Ups)
                tempFilteredExercisesSections[0].push(thisExercise);
            } else if (thisExercise["TYPE"] === globalExerciseTypeCoolDown) {
                // Add to the last section (Cool Downs)
                tempFilteredExercisesSections[tempFilteredExercisesSections.length - 1].push(thisExercise);
            } else {
                // Loop through all muscle groups and add the exercise to all relevant sections
                muscleGroups.forEach((muscleGroup) => {
                    let index = globalExerciseFilterMGArray.indexOf(muscleGroup);
                    if (index > -1) {
                        // Set the MUSCLE_GROUP to just the one it's being pushed into
                        let updatedExercise = { ...thisExercise }; // Create a copy for each section to prevent overwriting
                        updatedExercise["MUSCLE_GROUP"] = muscleGroup; // Set MUSCLE_GROUP to the current muscle group

                        tempFilteredExercisesSections[index].push(updatedExercise);
                        // tempFilteredExercisesSections[index].push(thisExercise);
                    }
                });
                // let index = globalExerciseFilterMGArray.indexOf(thisExercise["MUSCLE_GROUP"]);
                // tempFilteredExercisesSections[index].push(thisExercise);
            }
        }

        // After all exercises are categorized, set the filtered sections
        setFilteredExercisesSections(tempFilteredExercisesSections);

    }, [filteredExercises]);

    function closeModal(){
        document.getElementById("exerciseSearchModal").style.display = "none";
    }

    function displaySearchedRows(){

        let exerciseTypesToSearch = [];
        let muscleGroupsToSearch = [];

        for (let i= 0; i<selectedMuscleGroups.length; i++){
            if (selectedMuscleGroups[i] === 1) {
                exerciseTypesToSearch.push(globalExerciseTypeWarmUp);
            } else if (selectedMuscleGroups[i] === (globalExerciseFilterMGArray.length-1)) {
                exerciseTypesToSearch.push(globalExerciseTypeCoolDown);
            } else {
                if (!exerciseTypesToSearch.includes(0)){
                    exerciseTypesToSearch.push(0);
                }
                muscleGroupsToSearch.push(globalExerciseFilterMGArray[selectedMuscleGroups[i]]);
            }
        }

        if (selectedMuscleGroups.includes(0)){
            exerciseTypesToSearch = [0,1,2];
        }

        let equipmentsToSearch = [];

        for (let i = 0; i < selectedEquipments.length; i++){
            if (selectedEquipments[i] !== 0){
                equipmentsToSearch.push(globalExerciseFilterEquipmentsArray[selectedEquipments[i]]);
            }
        }

        console.log("equipmentsToSearch", equipmentsToSearch);
        console.log("muscleGroupsToSearch", muscleGroupsToSearch);
        console.log("exerciseTypesToSearch", exerciseTypesToSearch);
        //includes searches words in order only :
        //for string "The quick brown fox jumps over the lazy dog". "The quick brown fox" will work but not "The quick fox jumps lazy dog"
        //  row["NAME"].toLowerCase().includes(searchText.toLowerCase()))

        //https://stackoverflow.com/a/74367095/4331787
        //for string "The quick brown fox jumps over the lazy dog". "The quick fox jumps lazy dog" will work
        let queryWords = searchText.toLowerCase().split(' ').filter(queryWord => queryWord.length > 0);

        setFilteredExercises([...Exercises].filter(row => {

                // Parsing arrays for MUSCLE_GROUP and EQUIPMENT: If these fields start with [ (indicating a stringified array), they are parsed using JSON.parse(). Otherwise, they are treated as single-value arrays for consistency.

                // Handle MUSCLE_GROUP which might be a string or a stringified array
                let muscleGroups = [];
                if (row["MUSCLE_GROUP"].startsWith('[')) {
                    // Parse the stringified array
                    muscleGroups = JSON.parse(row["MUSCLE_GROUP"]);
                } else {
                    // Treat as a single value array for consistency
                    muscleGroups = [row["MUSCLE_GROUP"]];
                }

                // Handle EQUIPMENT which might be a string or a stringified array
                let equipment = [];
                if (row["EQUIPMENT"].startsWith('[')) {
                    // Parse the stringified array
                    equipment = JSON.parse(row["EQUIPMENT"]);
                } else {
                    // Treat as a single value array for consistency
                    equipment = [row["EQUIPMENT"]];
                }

            // 	Handle arrays in search: When searching, check if a field contains an array, and if so, match any of the array elements against the search terms.

            // Filter based on TYPE, MUSCLE_GROUP, and EQUIPMENT with includes(0) check
            const matchesMuscleGroup = (row["TYPE"] === 0 && !selectedMuscleGroups.includes(0) && muscleGroupsToSearch.length > 0)
                ? muscleGroups.some(muscle => muscleGroupsToSearch.includes(muscle)) // Check for any matching muscle groups
                    : true; // If no specific filter, allow all

                const matchesType = exerciseTypesToSearch.length > 0
                    ? exerciseTypesToSearch.includes(row["TYPE"])
                    : true; // If no filter, allow all

                const matchesEquipment = (!selectedEquipments.includes(0))
                    ? equipment.some(equip => equipmentsToSearch.includes(equip))
                    : true; // If no specific filter, allow all

                // Search for the NAME using queryWords
                const matchesQuery = queryWords.every(queryWord =>
                    row["NAME"].toLowerCase().split(' ').includes(queryWord) || row["NAME"].toLowerCase().includes(queryWord)
                );

                return matchesMuscleGroup && matchesType && matchesEquipment && matchesQuery;
            }).sort((a,b) => (a["NAME"]).localeCompare(b["NAME"]))
        );

        // setFilteredExercises([...Exercises].filter(row =>
        //             (row["TYPE"] === 0 && !selectedMuscleGroups.includes(0) && muscleGroupsToSearch.length > 0 ? muscleGroupsToSearch.includes(row["MUSCLE_GROUP"]) :  row)
        //             && (exerciseTypesToSearch.length > 0 ? exerciseTypesToSearch.includes(row["TYPE"]): row)
        //             && (!selectedEquipments.includes(0) ? equipmentsToSearch.includes(row["EQUIPMENT"]) : row)
        //             &&  queryWords.every(queryWord =>
        //                 row["NAME"].toLowerCase().split(' ').includes(queryWord)
        //                 ||  row["NAME"].toLowerCase().includes(queryWord)
        //             )
        //     )
        //         .sort((a,b) => (a["NAME"]).localeCompare(b["NAME"]))
        // );
    }

    function getFilterBar(){
        return (
            <div className="exercise-search-filter-bar">
                <div className={"exercise-search-filter-title"}>MUSCLE GROUPS:</div>
                <div className={"exercise-search-filter-bar-item"}>
                    {globalExerciseFilterMGArray.map((muscleGroup, index) => (
                                <MuscleGroupFilterItem key={index} index={index} selectedMuscleGroups={selectedMuscleGroups} setSelectedMuscleGroups={setSelectedMuscleGroups}/>
                    ))}
                </div>
                <div className={"exercise-search-filter-title"}>EQUIPMENTS:</div>
                <div className={"exercise-search-filter-bar-item"}>
                    {globalExerciseFilterEquipmentsArray.map((muscleGroup, index) => (
                        <EquipmentFilterItem key={index} index={index} selectedEquipments={selectedEquipments} setSelectedEquipments={setSelectedEquipments}/>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <div id={"exerciseSearchModal"} className={"modal"}>
            <div className="modal-content">
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <div className="exerciseSearchMainDiv">

                        {searchText !== undefined && <input value={searchText} onChange={e => setSearchText(e.target.value)} type="text" placeholder="Search" />}
                        {getFilterBar()}

                        {filteredExercises.length > 0 &&
                            <div className="exercise-results-count">
                                {filteredExercises.length + (filteredExercises.length > 1 ? " Results" : " Result")}
                            </div>
                        }

                        {filteredExercisesSections.map((section, index) => <ExerciseSearchResultSection exercises={section} setSelectedExercise={setSelectedExercise} key={index} exerciseMap={exerciseMap} /> )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExerciseSearch;