import React from 'react';
import ExerciseSearchResultCell from "./ExerciseSearchResultCell";

function ExerciseSearchResultSection({exercises, setSelectedExercise, exerciseMap}) {
    return (
        <>
            {exercises.map((exercise, index) =>
                // <>


                    <ExerciseSearchResultCell exercise={exercise}
                                              setSelectedExercise={setSelectedExercise}
                                              exerciseIndex={index} key={index} exerciseMap={exerciseMap} />


            )}
        </>
    );
}

export default ExerciseSearchResultSection;