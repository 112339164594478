import React from 'react';
import ExerciseQuickSearchResultCell from "./ExerciseQuickSearchResultCell";

function ExerciseQuickSearchResultSection({exercises, setSelectedExercise, exerciseMap}) {
    return (
        <>
            {exercises.map((exercise, index) =>
                // <>


                <ExerciseQuickSearchResultCell exercise={exercise}
                                          setSelectedExercise={setSelectedExercise}
                                          exerciseIndex={index} key={index} showHeader={true} exerciseMap={exerciseMap}/>


            )}
        </>
    );
}

export default ExerciseQuickSearchResultSection;