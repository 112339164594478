import React, {useEffect, useState} from 'react';
import arrowTop from './/assets/circuit_arrow_top.svg';
import arrowBottom from './/assets/circuit_arrow_bottom.svg';
import linkTop from './/assets/link-top.svg';
import linkBottom from './/assets/link-bottom.svg';
import swapIcon from './/assets/swap_horiz.svg'
import checkIcon from './/assets/check_circle.svg'
import cancelIcon from './/assets/cancel_FILL0_wght400_GRAD0_opsz24.svg'
import searchIcon from './/assets/search_FILL0_wght400_GRAD0_opsz24.svg'
import addIcon from './/assets/add_box_FILL1_wght400_GRAD0_opsz24.svg'
import deleteIcon from './/assets/delete_FILL1_wght400_GRAD0_opsz24.svg'
import {
    cleanString,
    globalExerciseFilterMGArray,
    globalExerciseTypeCoolDown, globalExerciseTypeWarmUp, globalMuscleGroupsArray,
    globalTypeWorkoutCompoundSet,
    globalTypeWorkoutSuperSet,
    globalWorkoutCircuitType,
    globalWorkoutCoolDownType,
    globalWorkoutNormalType,
    globalWorkoutWarmUpType
} from "./shared/Constants";
import Exercises from "./db/EXERCISE.json";
import ExerciseQuickSearchResultSection from "./ExerciseSearch/ExerciseQuickSearchResultSection";
import AddExerciseModal from "./AddExerciseModal";
import DeleteExerciseModal from "./DeleteExerciseModal";

function ExerciseClass({exercise, exerciseJSON, index, getHeaderDiv, sectionIndex, setTargetSection, setTargetIndex, programJson, setProgramJson, exerciseMap, selectedPhaseIndex, selectedWeekIndex, selectedDayIndex, addToUndoStack}) {

    const dayJSON = programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex];
    const sectionJSON = programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w[sectionIndex];

    const [repsTimeEditState, setRepsTimeEditState] = useState(false);
    const [flipTimeToRep, setFlipTimeToRep] = useState(false);
    const [flipRepsToTime, setFlipRepsToTime] = useState(false);
    const [inputActiveTimeMinutes, setInputActiveTimeMinutes] = useState(0);
    const [inputActiveTimeSeconds, setInputActiveTimeSeconds] = useState(30);
    const [inputReps, setInputReps] = useState(10);
    const [setsEditState, setSetsEditState] = useState(false);
    const [inputSets, setInputSets] = useState(1);
    const [restTimeEditState, setRestTimeEditState] = useState(false);
    const [inputRestTimeMinutes, setInputRestTimeMinutes] = useState(0);
    const [inputRestTimeSeconds, setInputRestTimeSeconds] = useState(0);
    const [showInputExercise, setShowInputExercise] = useState(false);
    const [typedExerciseName, setTypedExerciseName] = useState("");
    const [filteredExercises, setFilteredExercises] = useState([]);
    const [filteredExercisesSections, setFilteredExercisesSections] = useState([[]]);
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [deleteModalMessage, setDeleteModalMessage] = useState("");
    // const [clickedSectionIndex, setClickedSectionIndex] = useState(0);
    // const [clickedExerciseIndex, setClickedExerciseIndex] = useState(0);
    const [showAddExerciseModalFlag, setShowAddExerciseModalFlag] = useState(false);
    const [circuitRestEditState, setCircuitRestEditState] = useState(false);
    const [inputCircuitRestTimeMinutes, setInputCircuitRestTimeMinutes] = useState(0);
    const [inputCircuitRestTimeSeconds, setInputCircuitRestTimeSeconds] = useState(0);
    const [circuitRoundsEditState, setCircuitRoundsEditState] = useState(false);
    const [inputCircuitRounds, setInputCircuitRounds] = useState(0);
    const [showDeleteExerciseModalFlag, setShowDeleteExerciseModalFlag] = useState(false);
    const [beforeRepsTimeSwapJson, setBeforeRepsTimeSwapJson] = useState({});

    useEffect(() => {
        if (flipTimeToRep){
            setReps();
        }
    }, [flipTimeToRep]);

    useEffect(() => {
        if (flipRepsToTime){
            setActiveTime();
        }
    }, [flipRepsToTime]);

    useEffect(() => {
        displaySearchedRows();
    }, [typedExerciseName]);

    useEffect(() => {

        let tempFilteredExercisesSections = new Array(globalExerciseFilterMGArray.length);

        for (let i = 0; i < tempFilteredExercisesSections.length; i++){
            tempFilteredExercisesSections[i] = [];
        }

        for (let i = 0; i < filteredExercises.length; i++){
            const thisExercise = filteredExercises[i];

            // Handle the MUSCLE_GROUP field which might be a string or a stringified array
            let muscleGroups = [];
            if (thisExercise["MUSCLE_GROUP"].startsWith('[')) {
                // Parse the stringified array
                muscleGroups = JSON.parse(thisExercise["MUSCLE_GROUP"]);
            } else {
                // Treat as a single value array for consistency
                muscleGroups = [thisExercise["MUSCLE_GROUP"]];
            }

            if (thisExercise["TYPE"] === globalExerciseTypeWarmUp){
                // Add to the first section (Warm-Ups)
                tempFilteredExercisesSections[0].push(thisExercise);
            } else if (thisExercise["TYPE"] === globalExerciseTypeCoolDown) {
                // Add to the last section (Cool Downs)
                tempFilteredExercisesSections[tempFilteredExercisesSections.length - 1].push(thisExercise);
            } else {
                // Loop through all muscle groups and add the exercise to all relevant sections
                muscleGroups.forEach((muscleGroup) => {
                    let index = globalExerciseFilterMGArray.indexOf(muscleGroup);
                    if (index > -1) {
                        // Set the MUSCLE_GROUP to just the one it's being pushed into
                        let updatedExercise = { ...thisExercise }; // Create a copy for each section to prevent overwriting
                        updatedExercise["MUSCLE_GROUP"] = muscleGroup; // Set MUSCLE_GROUP to the current muscle group

                        tempFilteredExercisesSections[index].push(updatedExercise);
                        // tempFilteredExercisesSections[index].push(thisExercise);
                    }
                });
            }
        }

        // After all exercises are categorized, set the filtered sections
        setFilteredExercisesSections(tempFilteredExercisesSections);

    }, [filteredExercises]);

    useEffect(() => {
        if (selectedExercise !== null){
            console.log("selected: ", selectedExercise);
            console.log("targetSection: " + sectionIndex);
            console.log("targetIndex: " + index);

            let originalProgramJson = deepCopy({...programJson});
            let originalPArray = deepCopy([...programJson.p]);
            let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];
            newWArray[sectionIndex]["e"][index] = {...newWArray[sectionIndex]["e"][index],
                i : selectedExercise["ID"],
                mg: globalMuscleGroupsArray.indexOf(selectedExercise["MUSCLE_GROUP"])
            };
            console.log("programJson:");
            console.log(programJson);

            if (isThisCompoundSetSupersetCell()){
                if (exerciseMap.get(newWArray[sectionIndex]["e"][0].i)["MUSCLE_GROUP"] ===  exerciseMap.get(newWArray[sectionIndex]["e"][1].i)["MUSCLE_GROUP"]){
                    newWArray[sectionIndex]["tp"] = globalTypeWorkoutCompoundSet;
                    newWArray[sectionIndex]["n"] = "Compound Set";
                } else {
                    newWArray[sectionIndex]["tp"] = globalTypeWorkoutSuperSet;
                    newWArray[sectionIndex]["n"] = "Superset";
                }

            }

            console.log("newWArray:");
            console.log(newWArray);

            originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
            addToUndoStack(originalProgramJson);
            setProgramJson({...programJson, p: originalPArray});

            //hide Input Exercise
            disableInputExercise();
            //reset selectedExercise ID to 0
            setSelectedExercise(null);
        }
    }, [selectedExercise]);

    const handleKeyDown = (event, targetFunction) => {
        if (event.key === 'Enter') {
            console.log('do validate');
            targetFunction();
        }
    }

    function getRightSubText(){
        return (
            <div className="rightSubText">
                {!isThisCompoundSetSupersetFirstCell() &&
                    <>
                        <img src={addIcon} className="filter-system-blue swap-icon" alt={"add icon"} onClick={showAddExerciseModal}/>
                        <img src={deleteIcon} className="filter-red swap-icon" style={{marginRight: "-7px"}} alt={"delete icon"} onClick={showDeleteExerciseModal}/>
                    </>
                }
                {isThisCompoundSetSupersetLastCell() && <div className="compoundSetSuperSetTitleText">{sectionJSON["n"].toUpperCase()}</div>}
                {(exercise["PREMIUM"] === "TRUE") && <div className="premiumText" style={{marginBottom: 0}}>PREMIUM</div>}
                {(exercise["TYPE"] === globalExerciseTypeWarmUp) && <div className="warmUpText" style={{marginBottom: 0}}>WARM UP</div>}
                {(exercise["TYPE"] === globalExerciseTypeCoolDown) && <div className="coolDownText" style={{marginBottom: 0}}>COOL DOWN</div>}
            </div>
        )
    }

    function getRestTime(){
        if (isThisCompoundSetSupersetCell()) {
            if (sectionJSON.hasOwnProperty("rt")){
                const restTime = sectionJSON["rt"];
                return secondsToMMSSTimestamp(restTime);
            }
        } else {
            if (exerciseJSON.hasOwnProperty("rt")){
                const restTime = exerciseJSON["rt"];
                return secondsToMMSSTimestamp(restTime);
            }
        }
        return "";
    }

    function getActiveTime(){
        if (isThisCompoundSetSupersetCell()) {
            if (sectionJSON.hasOwnProperty("t")){
                const time = sectionJSON["t"];
                return secondsToMMSSTimestamp(time);
            }
        } else {
            if (exerciseJSON.hasOwnProperty("t")){
                const time = exerciseJSON["t"];
                return secondsToMMSSTimestamp(time);
            }
        }
        return "";
    }

    function getSets(){
        if (isThisCompoundSetSupersetCell()) {
            if (sectionJSON.hasOwnProperty("s")){
                const sets = sectionJSON["s"];
                return (sets>1) ? sets + " SETS" : sets + " SET";
            }
        } else {
            if (exerciseJSON.hasOwnProperty("s")){
                const sets = exerciseJSON["s"];
                return (sets>1) ? sets + " SETS" : sets + " SET";
            }
        }
        return "";
    }

    function getReps(){

        let reps;
        if (isThisCompoundSetSupersetCell()) {
            if (sectionJSON.hasOwnProperty("r")){
                reps = sectionJSON["r"];
            } else {
                return "";
            }
        } else {
            if (exerciseJSON.hasOwnProperty("r")){
                reps = exerciseJSON["r"];
            } else {
                return "";
            }
        }

        if (typeof reps === "string"){
            //reps range
            return (reps === "0") ? "AMRAP" : reps + " REPS";
        } else {
            //int reps
            return (reps>1) ? reps + " REPS" : reps + " REP";
        }
    }

    function secondsToMMSSTimestamp(timeInSeconds){
        if (timeInSeconds > 0){
            let mins = Math.floor(timeInSeconds / 60);
            let secs = timeInSeconds % 60;
            return ((mins < 10) ? "0" : "") + String(mins) + ":" + ((secs < 10) ? "0" : "") + String(secs);
        } else {
            return "0:00";
        }
    }

    function secondsToHHMMSSTimestamp(timeInSeconds){
        let finalString;
        let hours = Math.floor(timeInSeconds / 3600);
        let mins = Math.floor((timeInSeconds % 3600) / 60);
        let secs = Math.floor((timeInSeconds % 3600) % 60);

        if (hours > 0) {
            if (mins > 0) {
                finalString = ((hours>1) ? hours + " HRS" :  hours + " HR") + ((mins>1) ? " " + mins + " MINS" : " 1 MIN");
            } else {
                finalString = ((hours>1) ? hours + " HRS" : hours + " HR");
            }
        } else {
            if (secs > 0) {
                if (mins > 0) {
                    finalString = ((mins>1) ? mins + " MINS" : mins + " MIN") + ((secs>1) ? " " + secs + " SECS" : " 1 SEC");
                } else {
                    finalString = ((secs>1) ? secs + " SECS" : "1 SEC");
                }
            } else {
                finalString = ((mins>1) ? mins + " MINS" : mins + " MIN");
            }
        }

        return finalString;

    }

    function isThisNormalExerciseCell(){
        const workoutType = dayJSON.w[sectionIndex]["tp"];
        return workoutType === globalWorkoutNormalType;
    }

    function isThisLastNormalExerciseCellInSection(){
        return index === (sectionJSON["e"].length - 1);
    }

    function isThisStretchingLastCell(){
        const workoutType = sectionJSON["tp"];
        if (workoutType === globalWorkoutWarmUpType || workoutType === globalWorkoutCoolDownType) {
            return index === (sectionJSON["e"].length - 1);
        }
        return false;
    }

    function isThisCircuitCell(){
        const workoutType = sectionJSON["tp"];
        return workoutType === globalWorkoutCircuitType;
    }

    function isThisCompoundSetSupersetFirstCell(){
        const workoutType = sectionJSON["tp"];
        if (workoutType === globalTypeWorkoutCompoundSet || workoutType === globalTypeWorkoutSuperSet) {
            if (index === 0){
                return true;
            }
        }
        return false;
    }

    function isThisCompoundSetSupersetLastCell(){
        const workoutType = sectionJSON["tp"];
        if (workoutType === globalTypeWorkoutCompoundSet || workoutType === globalTypeWorkoutSuperSet) {
            if (index > 0){
                return true;
            }
        }
        return false;
    }

    function isThisCompoundSetSupersetCell(){
        const workoutType = sectionJSON["tp"];
        return workoutType === globalTypeWorkoutCompoundSet || workoutType === globalTypeWorkoutSuperSet;
    }

    function isThisCompoundSetCell(){
        const workoutType = sectionJSON["tp"];
        return workoutType === globalTypeWorkoutCompoundSet;
    }

    function isThisSupersetCell(){
        const workoutType = sectionJSON["tp"];
        return workoutType === globalTypeWorkoutSuperSet;
    }

    function isThisLastCircuitCell(){
        const workoutType = sectionJSON["tp"];
        if (workoutType === globalWorkoutCircuitType) {
            return index === (sectionJSON["e"].length - 1);
        }
        return false;
    }

    function isThisWarmUpStretchingCell(){
        const workoutType = dayJSON.w[sectionIndex]["tp"];
        return workoutType === globalWorkoutWarmUpType;
    }

    function isThisCoolDownStretchingCell(){
        const workoutType = dayJSON.w[sectionIndex]["tp"];
        return workoutType === globalWorkoutCoolDownType;
    }

    function doesWarmUpExist(){
        const workoutType = dayJSON.w[0]["tp"];
        return workoutType === globalWorkoutWarmUpType;
    }

    function doesCoolDownExist(){
        const lastSectionIndex = dayJSON.w.length-1;
        if (lastSectionIndex > -1){
            const workoutType = dayJSON.w[lastSectionIndex]["tp"];
            return workoutType === globalWorkoutCoolDownType;
        }
        return false;
    }

    function getSetsRepsInfo(){
        const workoutType = sectionJSON["tp"];

        if (workoutType === globalWorkoutNormalType || workoutType === globalWorkoutWarmUpType || workoutType === globalWorkoutCoolDownType) {
            if (exerciseJSON.hasOwnProperty("r")) {
                // sets n reps type normal workout
                // console.log("here");
                // console.log(exercise["NAME"]);
                return getSetsRepsRest(getRepsDiv(), getSetsDiv(), getRestTimeDiv());
            } else {
                // time based normal workout
                return getSetsRepsRest(getActiveTimeDiv(), getSetsDiv(), getRestTimeDiv());
            }

        } else if (workoutType === globalWorkoutCircuitType) {
            // Circuit Type

            if (index < (sectionJSON["e"].length - 1)) {
                //normal circuit cell
            } else {
                //last circuit cell
            }

            if (exerciseJSON.hasOwnProperty("r")) {
                // sets n reps type
                return getSetsRepsRest(getRepsDiv(), "", getRestTimeDiv());
            } else {
                // time based
                return getSetsRepsRest(getActiveTimeDiv(), "", getRestTimeDiv());
            }

        } else if (workoutType === globalTypeWorkoutCompoundSet || workoutType === globalTypeWorkoutSuperSet) {

            if (index === 0) {
                //top row
                return (<></>);
            } else {
                //bottom row
                if (sectionJSON.hasOwnProperty("r")) {
                    // sets n reps type
                    return getSetsRepsRest(getRepsDiv(), getSetsDiv(), getRestTimeDiv());
                } else {
                    // time based
                    return getSetsRepsRest(getActiveTimeDiv(), getSetsDiv(), getRestTimeDiv());
                }
            }

        } else {
            return getSetsRepsRest("Others", "Contact", "Developer");
        }
    }

    function getRepsDiv(){
        if (repsTimeEditState){
            return getRepsSwapDiv();
        } else {
            return (
                <div className="sets-cell tooltip" onClick={enableRepsTimeEditState}>{getReps()}
                    <span className="tooltiptext sets-cell-tooltiptext">Change Reps</span>
                </div>
            )
        }
    }

    function getActiveTimeDiv(){
        if (repsTimeEditState){
            return getActiveTimeSwapDiv();
        } else {
            return (
                <div className="sets-cell tooltip" onClick={enableRepsTimeEditState}>{getActiveTime()}
                    <span className="tooltiptext sets-cell-tooltiptext">Change Active Time</span>
                </div>
            )
        }
    }

    function getActiveTimeSwapDiv(){
        return (
            <div className={"reps-swap-div"}>
                <input id='m' name='m' type='number' min='0' max='59' value={inputActiveTimeMinutes} onChange={onChangeActiveTimeMinutes} onKeyDown={(e) => handleKeyDown(e, doneEditingActiveTime)}/>:
                <input id='s' name='s' type='number' min='0' max='59' value={inputActiveTimeSeconds} onChange={onChangeActiveTimeSeconds} onKeyDown={(e) => handleKeyDown(e, doneEditingActiveTime)}/>
                <div className="sets-cell tooltip" style={{display: "flex"}}>
                    <img src={swapIcon} className="filter-exerprise-orange swap-icon" alt={"swap icon"} onClick={swapActiveTimeToReps}/>
                    <span className="tooltiptext sets-cell-tooltiptext">Swap to Reps</span>
                </div>
                <img src={checkIcon} className="filter-green swap-icon" alt={"done icon"} onClick={doneEditingActiveTime}/>
                <img src={cancelIcon} className="swap-icon" alt={"cancel icon"} onClick={cancelRepsTimeEditState}/>
            </div>
        )
    }

    function getRepsSwapDiv(){
        return (
        <div className={"reps-swap-div"}>
            <input id='r' name='r' type='number' min='0' max='100' value={inputReps} onChange={onChangeReps} onKeyDown={(e) => handleKeyDown(e, doneEditingReps)}/>
            {/*{getReps()}*/}
            <div className="sets-cell tooltip" style={{display: "flex"}}>
                <img src={swapIcon} className="filter-exerprise-orange swap-icon" alt={"swap icon"} onClick={swapRepsToActiveTime}/>
                <span className="tooltiptext sets-cell-tooltiptext">Swap to Time</span>
            </div>
            <img src={checkIcon} className="filter-green swap-icon" alt={"done icon"} onClick={doneEditingReps}/>
            <img src={cancelIcon} className="swap-icon" alt={"cancel icon"} onClick={cancelRepsTimeEditState}/>
        </div>
        )
    }

    function getSetsDiv(){
        if (setsEditState){
            return getEditSetsDiv();
        } else {
            return (
                <div className="sets-cell tooltip" onClick={enableSetsEditState}>{getSets()}
                    <span className="tooltiptext sets-cell-tooltiptext">Change Sets</span>
                </div>
            )
        }
    }

    function getEditSetsDiv(){
        return (
            <div className={"reps-swap-div"}>
                <input id='s' name='s' type='number' min='0' max='100' value={inputSets} onChange={onChangeSets} onKeyDown={(e) => handleKeyDown(e, doneEditingSets)}/>
                <img src={checkIcon} className="filter-green swap-icon" alt={"done icon"} onClick={doneEditingSets}/>
            </div>
        )
    }

    function getRestTimeDiv(){
        if (restTimeEditState){
            return getEditRestTimeDiv();
        } else {
            return (
                <div className="sets-cell tooltip" onClick={enableRestTimeEditState}>{getRestTime()}
                    <span className="tooltiptext sets-cell-tooltiptext">Change Rest Time</span>
                </div>
            )
        }
    }

    function getEditRestTimeDiv(){
        return (
            <div className={"reps-swap-div"}>
                <input id='rm' name='rm' type='number' min='0' max='59' value={inputRestTimeMinutes} onChange={onChangeRestTimeMinutes} onKeyDown={(e) => handleKeyDown(e, doneEditingRestTime)}/>:
                <input id='rs' name='rs' type='number' min='0' max='59' value={inputRestTimeSeconds} onChange={onChangeRestTimeSeconds} onKeyDown={(e) => handleKeyDown(e, doneEditingRestTime)}/>
                <img src={checkIcon} className="filter-green swap-icon" alt={"done icon"} onClick={doneEditingRestTime}/>
            </div>
        )
    }

    function getSetsRepsRest(repsOrActiveTimeSwapDiv, setsDiv, restDiv){
        return (
            <div className="setsReps">
                {repsOrActiveTimeSwapDiv}
                {setsDiv}
                {restDiv}
            </div>
        )
    }

    function getCircuitBottomDiv(){
        let roundText = "round";

        const rounds = sectionJSON["cs"];
        if (rounds > 1) {
            roundText = roundText + "s";
        }

        // const bottomText = ("CIRCUIT: " + rounds + " " + roundText + " " + secondsToHHMMSSTimestamp(sectionJSON["crt"]) + " rest").toUpperCase();

        return (
            <div className="exercise-header circuit-bottom-div">
                {/*{bottomText}*/}
                CIRCUIT:&nbsp;
                { circuitRoundsEditState ? getCircuitBottomRoundsEditDiv() :
                    <div onClick={() => setCircuitRoundsEditState(true)} style={{cursor: "pointer"}}>
                        {rounds}&nbsp;
                    </div>
                }
                {roundText.toUpperCase()}&nbsp;
                { circuitRestEditState ? getCircuitBottomRestEditDiv() :
                    <div onClick={() => setCircuitRestEditState(true)} style={{cursor: "pointer"}}>
                        {secondsToHHMMSSTimestamp(sectionJSON["crt"])}&nbsp;
                    </div>
                }
                REST
            </div>
        )
    }

    function getCircuitBottomRestEditDiv(){
        return (
            <div className={"reps-swap-div"}>
                <input id='crm' name='crm' type='number' min='0' max='59' value={inputCircuitRestTimeMinutes} onChange={onChangeCircuitRestTimeMinutes} onKeyDown={(e) => handleKeyDown(e, doneEditingCircuitRestTime)}/>:
                <input id='crs' name='crs' type='number' min='0' max='59' value={inputCircuitRestTimeSeconds} onChange={onChangeCircuitRestTimeSeconds} onKeyDown={(e) => handleKeyDown(e, doneEditingCircuitRestTime)}/>
                <img src={checkIcon} className="filter-green swap-icon" alt={"done icon"} onClick={doneEditingCircuitRestTime}/>
            </div>
        )
    }

    function getCircuitBottomRoundsEditDiv(){
        return (
            <div className={"reps-swap-div"}>
                <input id='rd' name='rd' type='number' min='0' max='59' value={inputCircuitRounds} onChange={onChangeCircuitRounds} onKeyDown={(e) => handleKeyDown(e, doneEditingCircuitRounds)}/>
                <img src={checkIcon} className="filter-green swap-icon" alt={"done icon"} onClick={doneEditingCircuitRounds}/>
            </div>
        )
    }

    function getExerciseLeftDiv(){
        return (
            <div className="exerciseLeftDiv exerciseLeftDivPadding">
                <img className="exerciseImage" src= {"/thumbs/" + exercise["ID"] + ".jpg"} loading="lazy" alt={exercise["NAME"] + " exercise thumbnail"}/>
            </div>
        )
    }

    function getCircuitArrowDiv(){
        return (
            <div className="exerciseName backgroundLightOrange exerciseCircuitNumberDiv">
                <img src={arrowBottom} className={"filter-exerprise-orange" + ((index === 0) ? " opacityZero" : "")} alt={"down arrow"}/>
                <div className="circuitExerciseNumber">{index + 1}</div>
                {!isThisLastCircuitCell() && <img src={arrowTop} className="filter-exerprise-orange" alt={"down arrow"}/>}
            </div>
        )
    }

    function getMuscleGroupString(){
        // if mg index is stored, pick that muscle group
        if (exerciseJSON.hasOwnProperty("mg")){
            return globalMuscleGroupsArray[exerciseJSON["mg"]];
        } else {
            return cleanString(exercise["MUSCLE_GROUP"]);
        }
    }

    function getExerciseCellRightDiv(){
        return (
            <>
                <div className={"exerciseCellRightDiv exerciseCellRightDivPadding " + (isThisCircuitCell() ? " backgroundLightOrange" : "") + (isThisCompoundSetSupersetCell() ? " backgroundLightBlue" : "")}>
                    {isThisCompoundSetSupersetLastCell() &&  <div className="compoundSetSuperset-link-div"><img src={linkBottom} alt={"link symbol"}/></div>}
                    <div className="flexGrowOne exerciseNameDescDiv">
                        <div >
                            {showInputExercise ? getInputExerciseNameDiv() : getDefaultExerciseNameDiv()}
                        </div>
                        <div className="muscleGroupEquipment">
                            {getMuscleGroupString()} | {cleanString(exercise["EQUIPMENT"])} {exercise["EXTRA_EQUIPMENT"] && "| " + cleanString(exercise["EXTRA_EQUIPMENT"])}
                        </div>
                    </div>
                    {getSetsRepsInfo()}
                    {getRightSubText()}
                    {isThisCompoundSetSupersetFirstCell() &&  <div className="compoundSetSuperset-link-top"><img src={linkTop} alt={"link symbol"}/> </div>}
                </div>
            </>
        )
    }

    function showExerciseSearchModal(){
        setTargetSection(sectionIndex);
        setTargetIndex(index);
        document.getElementById("exerciseSearchModal").style.display = "flex";
    }

    // Reps & Active time swapping and Sets and rest time editing code starts ==============================

    function enableRepsTimeEditState(){
        //catch original json here and restore it later
        let originalPJson = deepCopy({...programJson});
        setBeforeRepsTimeSwapJson(originalPJson);
        setRepsTimeEditState(true);
    }

    function enableSetsEditState(){
        //catch original json here and restore it later
        setSetsEditState(true);
    }

    function cancelRepsTimeEditState(){
        setProgramJson(deepCopy(beforeRepsTimeSwapJson));
        setRepsTimeEditState(false)
    }

    function enableRestTimeEditState() {
        setRestTimeEditState(true);
    }

    const onChangeActiveTimeMinutes = (e) => {
        setInputActiveTimeMinutes(e.target.value);
    };

    const onChangeActiveTimeSeconds = (e) => {
        setInputActiveTimeSeconds(e.target.value);
    };

    const onChangeReps = (e) => {
        setInputReps(e.target.value);
    };

    const onChangeSets = (e) => {
        const val = e.target.value;
        // if (val){
        setInputSets(val);
        // }
    };

    const onChangeRestTimeMinutes = (e) => {
        setInputRestTimeMinutes(e.target.value);
    };

    const onChangeRestTimeSeconds = (e) => {
        setInputRestTimeSeconds(e.target.value);
    };

    const onChangeCircuitRounds = (e) => {
        setInputCircuitRounds(e.target.value);
    };

    const onChangeCircuitRestTimeMinutes = (e) => {
        setInputCircuitRestTimeMinutes(e.target.value);
    };

    const onChangeCircuitRestTimeSeconds = (e) => {
        setInputCircuitRestTimeSeconds(e.target.value);
    };

    function doneEditingReps(){
        let originalProgramJson = deepCopy(beforeRepsTimeSwapJson);
        let originalPArray = deepCopy([...programJson.p]);
        let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];

        if (isThisCompoundSetSupersetCell()) {
            newWArray[sectionIndex]["r"] = inputReps;
        } else {
            exerciseJSON["r"] = inputReps;
            newWArray[sectionIndex]["e"][index] = deepCopy(exerciseJSON);

        }
        console.log("newWArray:");
        console.log(newWArray);

        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
        setRepsTimeEditState(false);
    }

    function doneEditingActiveTime(){
        let originalProgramJson = deepCopy(beforeRepsTimeSwapJson);
        let originalPArray = deepCopy([...programJson.p]);

        let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];

        const inputTimeInSeconds = (parseFloat(inputActiveTimeMinutes*60) + parseFloat(inputActiveTimeSeconds));

        if (isThisCompoundSetSupersetCell()) {
            newWArray[sectionIndex]["t"] = inputTimeInSeconds;
        } else {
            exerciseJSON["t"] = inputTimeInSeconds;
            newWArray[sectionIndex]["e"][index] = deepCopy(exerciseJSON);
        }
        console.log("newWArray:");
        console.log(newWArray);

        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;

        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
        setRepsTimeEditState(false);
    }

    function doneEditingSets(){
        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);
        let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];

        if (isThisCompoundSetSupersetCell()) {
            newWArray[sectionIndex]["s"] = inputSets;
        } else {
            exerciseJSON["s"] = inputSets;
            newWArray[sectionIndex]["e"][index] = deepCopy(exerciseJSON);
        }
        console.log("newWArray:");
        console.log(newWArray);

        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
        setSetsEditState(false);
    }

    function doneEditingCircuitRestTime(){
        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);
        let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];

        const inputCircuitRestTimeInSeconds = (parseFloat(inputCircuitRestTimeMinutes*60) + parseFloat(inputCircuitRestTimeSeconds));
        newWArray[sectionIndex]["crt"] = inputCircuitRestTimeInSeconds;
        console.log("newWArray:");
        console.log(newWArray);

        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
        setCircuitRestEditState(false);
    }

    function doneEditingCircuitRounds() {
        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);
        let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];

        newWArray[sectionIndex]["cs"] = inputCircuitRounds;
        console.log("newWArray:");
        console.log(newWArray);

        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
        setCircuitRoundsEditState(false);
    }

    function doneEditingRestTime(){
        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);
        let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];

        const inputRestTimeInSeconds = (parseFloat(inputRestTimeMinutes*60) + parseFloat(inputRestTimeSeconds));

        if (isThisCompoundSetSupersetCell()) {
            newWArray[sectionIndex]["rt"] = inputRestTimeInSeconds;
        } else {
            exerciseJSON["rt"] = inputRestTimeInSeconds;
            newWArray[sectionIndex]["e"][index] = deepCopy(exerciseJSON);
        }
        console.log("newWArray:");
        console.log(newWArray);

        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
        setRestTimeEditState(false);
    }

    function swapActiveTimeToReps(){
        setFlipTimeToRep(true);
    }

    function swapRepsToActiveTime() {
        console.log("swapRepsToActiveTime");
        setFlipRepsToTime(true);
    }

    function setReps(){
        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);
        let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];

        if (isThisCompoundSetSupersetCell()) {
            delete newWArray[sectionIndex].t;
            newWArray[sectionIndex]["r"] = inputReps;
        } else {
            delete exerciseJSON.t
            exerciseJSON["r"] = inputReps;
            newWArray[sectionIndex]["e"][index] = deepCopy(exerciseJSON);
        }
        console.log("newWArray:");
        console.log(newWArray);

        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
        setFlipTimeToRep(false);
    }

    function setActiveTime(){
        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);
        let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];
        const inputTimeInSeconds = ((inputActiveTimeMinutes*60) + inputActiveTimeSeconds);

        if (isThisCompoundSetSupersetCell()) {
            delete newWArray[sectionIndex].r;
            newWArray[sectionIndex]["t"] = inputTimeInSeconds;
        } else {
            delete exerciseJSON.r
            exerciseJSON["t"] = inputTimeInSeconds;
            newWArray[sectionIndex]["e"][index] = deepCopy(exerciseJSON);
        }
        console.log("newWArray:");
        console.log(newWArray);

        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
        setFlipRepsToTime(false);
    }

    // Reps & Active time swapping and Sets and rest time editing code ends ==============================

    // Quick Search related code starts ================================

    function enableInputExercise(){
        setTypedExerciseName("");
        setShowInputExercise(true);
    }

    function disableInputExercise() {
        setShowInputExercise(false);
    }

    function getDefaultExerciseNameDiv(){
        return (
            <div className="defaultExerciseNameDiv">
                <a className="exerciseName tooltip" onClick={enableInputExercise}>{exercise["NAME"]}
                    <span className="tooltiptext">Change Exercise</span>
                </a>
                <div className="exerciseSearchIconDiv">
                    <img src={searchIcon} className="filter-grey swap-icon" alt={"search icon"} onClick={showExerciseSearchModal}/>
                </div>
            </div>
        )
    }

    function getInputExerciseNameDiv(){
        return (
            <>
                {typedExerciseName !== undefined && <input className="exerciseName" size={35} value={typedExerciseName} onChange={e => setTypedExerciseName(e.target.value)} />}
                <img src={cancelIcon} className="filter-grey" style={{cursor: "pointer"}} alt={"cancel icon"} onClick={disableInputExercise}/>
                <div className="quickExerciseSearchMainDiv">
                    {getSearchedRows()}
                </div>
            </>
        )
    }

    function getSearchedRows() {
        return (
            <>
                {typedExerciseName.length > 2 &&
                    filteredExercisesSections.map((section, index) => <ExerciseQuickSearchResultSection
                        exercises={section} setSelectedExercise={setSelectedExercise} key={index} exerciseMap={exerciseMap}/>)
                }
            </>
        )
    }

    function displaySearchedRows(){
        //includes searches words in order only :
        //for string "The quick brown fox jumps over the lazy dog". "The quick brown fox" will work but not "The quick fox jumps lazy dog"
        //  row["NAME"].toLowerCase().includes(searchText.toLowerCase()))

        //https://stackoverflow.com/a/74367095/4331787
        //for string "The quick brown fox jumps over the lazy dog". "The quick fox jumps lazy dog" will work
        let queryWords = typedExerciseName.toLowerCase().split(' ').filter(queryWord => queryWord.length > 0);

        setFilteredExercises([...Exercises].filter(row =>
                queryWords.every(queryWord =>
                    row["NAME"].toLowerCase().split(' ').includes(queryWord)
                    ||  row["NAME"].toLowerCase().includes(queryWord)
                )
            )
                .sort((a,b) => (a["NAME"]).localeCompare(b["NAME"]))
        );
    }

    // Quick Search related code ends ================================

    // Add exercise related code starts ===============================

    function showAddExerciseModal(){
        console.log("showAddExerciseModal");
        if (isThisWarmUpStretchingCell() && !isThisStretchingLastCell()){
            duplicateThisExercise();
        } else if (isThisCoolDownStretchingCell()){
            duplicateThisExercise();
        } else if(isThisCircuitCell() && !isThisLastCircuitCell()) {
            duplicateThisExercise();
        } else {
            setShowAddExerciseModalFlag(true);
        }
    }

    function hideAddExerciseModal(){
        setShowAddExerciseModalFlag(false);
    }

    function addExercise(){
        if (isThisNormalExerciseCell()){
            console.log("thisIsNormalExercise");
            duplicateThisExercise();
            hideAddExerciseModal();
        } else if (isNextSectionANormalExerciseSection()){
            console.log("isNextSectionANormalExerciseSection");
            duplicateNextExercise();
            hideAddExerciseModal();
        } else {
            console.log("else!");
            createExerciseSection();
            hideAddExerciseModal();
        }
    }

    function addSuperSetCompoundSet(){
        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);
        let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];

        if (isThisNormalExerciseCell() && !isThisLastNormalExerciseCellInSection()){
            let nextEArray = newWArray[sectionIndex]["e"];

            const origEArray = nextEArray.splice(0, index+1);
            newWArray[sectionIndex]["e"] = deepCopy(origEArray);

            //deep copy
            let newExerciseSectionJSON = deepCopy(newWArray[sectionIndex]);
            newExerciseSectionJSON["e"] = deepCopy(nextEArray);

            newWArray.splice(sectionIndex + 1,0, newExerciseSectionJSON);
        }

        let nextSectionJSON = {
            tp: globalTypeWorkoutCompoundSet,
            s:2,
            rt: 60,
            t: 45,
            n:"Compound Set",//Superset
            e: [{i: 1},{i: 1}]
        };
        newWArray.splice(sectionIndex + 1,0, nextSectionJSON);
        console.log("newWArray:");
        console.log(newWArray);
        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
        hideAddExerciseModal();
    }

    function addCircuitSection(){
        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);
        let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];

        if (isThisNormalExerciseCell() && !isThisLastNormalExerciseCellInSection()){
            let nextEArray = newWArray[sectionIndex]["e"];

            const origEArray = nextEArray.splice(0, index+1);
            newWArray[sectionIndex]["e"] = deepCopy(origEArray);

            //deep copy
            let newExerciseSectionJSON = deepCopy(newWArray[sectionIndex]);
            newExerciseSectionJSON["e"] = nextEArray;

            newWArray.splice(sectionIndex + 1,0, newExerciseSectionJSON);
        }

        let nextSectionJSON = {
            tp: globalWorkoutCircuitType,
            cs:2,
            rd: 1,
            crt: 60,
            e: [{"rt":20,"i":1,"t":40},{"rt":20,"i":1,"t":40}]
        };
        newWArray.splice(sectionIndex + 1,0, nextSectionJSON);
        console.log("newWArray:");
        console.log(newWArray);
        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
        hideAddExerciseModal();
    }

    function duplicateThisExercise(){
        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);
        let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];
        let originalEArray = newWArray[sectionIndex]["e"];
        console.log("before: ");
        console.log(originalEArray);
        originalEArray.splice(index+1, 0, deepCopy(originalEArray[index]));
        console.log("after:");
        console.log(originalEArray);
        newWArray[sectionIndex]["e"] = originalEArray;
        console.log("newWArray:");
        console.log(newWArray);
        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
    }

    function deepCopy(json){
        return JSON.parse(JSON.stringify(json));
    }

    function duplicateNextExercise(){
        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);
        let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];
        let nextSectionIndex = sectionIndex + 1;
        let originalNextEArray = newWArray[nextSectionIndex]["e"];
        console.log("before: ");
        console.log(originalNextEArray);
        originalNextEArray.splice(1, 0, deepCopy(originalNextEArray[0]));
        console.log("after:");
        console.log(originalNextEArray);
        newWArray[nextSectionIndex]["e"] = originalNextEArray;
        console.log("newWArray:");
        console.log(newWArray);
        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
    }

    function createExerciseSection(){
        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);
        let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];
        let nextSectionJSON = {
            tp: 1,
            e: [
                {
                    rt: 60,
                    t: 45,
                    s: 2,
                    i: 1
                }
            ]
        };
        newWArray.splice(sectionIndex + 1,0, nextSectionJSON);
        console.log("newWArray:");
        console.log(newWArray);
        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
    }

    function addWarmUpSection(){
        if (isThisWarmUpStretchingCell()){
            duplicateThisExercise();
            hideAddExerciseModal();
        } else {
            let originalProgramJson = deepCopy({...programJson});
            let originalPArray = deepCopy([...programJson.p]);
            let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];
            let nextSectionJSON = {
                tp: globalWorkoutWarmUpType,
                e: [{"rt":0,"t":30,"s":1,"i":928}]
            };
            //warm up should always be the first section
            newWArray.splice(0,0, nextSectionJSON);
            console.log("newWArray:");
            console.log(newWArray);
            originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
            addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
            hideAddExerciseModal();
        }
    }

    function addCoolDownSection(){
        if (isThisCoolDownStretchingCell()){
            duplicateThisExercise();
            hideAddExerciseModal();
        } else {
            let originalProgramJson = deepCopy({...programJson});
            let originalPArray = deepCopy([...programJson.p]);
            let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];
            let nextSectionJSON = {
                tp: globalWorkoutCoolDownType,
                e: [{"rt":0,"t":30,"s":1,"i":929}]
            };
            newWArray.splice(dayJSON.w.length,0, nextSectionJSON);
            console.log("newWArray:");
            console.log(newWArray);
            originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
            addToUndoStack(originalProgramJson);
            setProgramJson({...programJson, p: originalPArray});
            hideAddExerciseModal();
        }
    }

    function isNextSectionANormalExerciseSection(){
        const wArray = dayJSON.w;
        console.log("sectionIndex:");
        console.log(sectionIndex);
        console.log("next sectionIndex:");
        console.log(sectionIndex + 1);
        if ((sectionIndex + 1) < wArray.length ){
            const nextSectionJSON = wArray[sectionIndex + 1];
            console.log("nextSectionJSON");
            console.log(nextSectionJSON);
            const nextWorkoutType = nextSectionJSON["tp"];
            if (nextWorkoutType === globalWorkoutNormalType){
                return true;
            }
        }
        return false;
    }

    function shouldShowAddWarmUpButton(){
        if (doesWarmUpExist()){
            if (!isThisWarmUpStretchingCell()){
                return false;
            }
        }
        return true;
    }
    function shouldShowAddCoolDownButton(){
        if (doesCoolDownExist()){
            if (!isThisCoolDownStretchingCell()){
                return false;
            }
        }
        return true;
    }

    function showDeleteExerciseModal(){
        if (isThisCompoundSetCell()){
            setDeleteModalMessage("Are you sure you want to delete this compound set?");
        } else if (isThisSupersetCell()){
            setDeleteModalMessage("Are you sure you want to delete this super set?");
        } else if (isThisCircuitCell() && (sectionJSON["e"].length < 3)){
            setDeleteModalMessage("Are you sure you want to delete this circuit?");
        } else {
            setDeleteModalMessage("Are you sure you want to delete this exercise?");
        }
        setShowDeleteExerciseModalFlag(true);
    }

    function hideDeleteExerciseModal(){
        setShowDeleteExerciseModalFlag(false);
    }

    function shouldThisSectionBeDeleted(){
        if (isThisCompoundSetSupersetCell()){
            return true;
        } else if (isThisCircuitCell() && (sectionJSON["e"].length < 3)){
            return true;
        } else if (sectionJSON["e"].length === 1){
            return true;
        }
        return false;
    }

    function deleteExercise(){
        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);
        let newWArray = [...programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w];

        if (shouldThisSectionBeDeleted()){
            newWArray.splice(sectionIndex, 1);
        } else {
            let originalEArray = newWArray[sectionIndex]["e"];
            originalEArray.splice(index, 1);
            newWArray[sectionIndex]["e"] = originalEArray;
        }

        hideDeleteExerciseModal();
        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
    }

    // Add exercise related code ends ===============================

    return (
        <>
            { showAddExerciseModalFlag && <AddExerciseModal addExercise={addExercise} addSuperSetCompoundSet={addSuperSetCompoundSet} addCircuitSection={addCircuitSection} addWarmUpSection={addWarmUpSection} addCoolDownSection={addCoolDownSection} setShowAddExerciseModalFlag={setShowAddExerciseModalFlag} shouldShowAddWarmUpButton={shouldShowAddWarmUpButton} shouldShowAddCoolDownButton={shouldShowAddCoolDownButton} />}
            { showDeleteExerciseModalFlag && <DeleteExerciseModal deleteModalMessage={deleteModalMessage} deleteExercise={deleteExercise} setShowDeleteExerciseModalFlag={setShowDeleteExerciseModalFlag}/>}

            {(index === 0) && getHeaderDiv()}

            <div className="exercise-cell">
                {getExerciseLeftDiv()}
                {isThisCircuitCell() && getCircuitArrowDiv()}
                {getExerciseCellRightDiv()}
            </div>

            {isThisLastCircuitCell() && getCircuitBottomDiv()}

            {(!isThisCircuitCell() && !isThisCompoundSetSupersetFirstCell()) && <div className="exercise-separater"></div>}
        </>
    );
}

export default ExerciseClass;