import React from 'react';
import {cleanString, globalExerciseTypeCoolDown, globalExerciseTypeWarmUp} from "../shared/Constants";

function ExerciseQuickSearchResultCell({exercise, setSelectedExercise, exerciseIndex, showHeader, exerciseMap}) {
    function onExerciseSelected(){
        setSelectedExercise(exercise);
    }

    function getRightSubText(){
        return (
            <div className="quickExerciseSearchRightSubText">
                {(exercise["PREMIUM"] === "TRUE") && <div className="premiumText">PREMIUM</div>}
                {(exercise["TYPE"] === globalExerciseTypeWarmUp) && <div className="warmUpText">WARM UP</div>}
                {(exercise["TYPE"] === globalExerciseTypeCoolDown) && <div className="coolDownText">COOL DOWN</div>}
            </div>
        )
    }

    function getExerciseCellRightDiv(){
        return (
            <>
                <div className={"quickExerciseSearchExerciseCellRightDiv"}>
                    <div className="flexGrowOne exerciseNameDescDiv">
                        <div className="quickSearchExerciseName">{exercise["NAME"]}</div>
                        <div className="muscleGroupEquipment">
                            {cleanString(exerciseMap.get(exercise["ID"])["MUSCLE_GROUP"])} | {cleanString(exercise["EQUIPMENT"])} {exercise["EXTRA_EQUIPMENT"] && "| " + cleanString(exercise["EXTRA_EQUIPMENT"])}
                        </div>
                        {getRightSubText()}
                    </div>
                    {/*{getRightSubText()}*/}
                </div>
            </>
        )
    }

    function getExerciseLeftDiv(){
        return (
            <div className="quickExerciseSearchExerciseCellLeftDiv">
                <img className="quickSearchExerciseImage" src= {"/thumbs/" + exercise["ID"] + ".jpg"} loading="lazy" alt={exercise["NAME"] + " exercise thumbnail"}/>
            </div>
        )
    }

    function getSectionHeaderTitle(){
        const exerciseType = exercise["TYPE"];

        if (exerciseType === globalExerciseTypeWarmUp){
            return "Dynamic Warm Up Stretching";
        } else if (exerciseType === globalExerciseTypeCoolDown ){
            return "Static Cool Down Stretching";
        } else {
            return exercise["MUSCLE_GROUP"];
        }
    }

    return (
        <>
            {showHeader && exerciseIndex === 0 &&
                <div className={"quick-exercise-header exercise-search-cell-header"}>{getSectionHeaderTitle()}</div>
            }

            <div className={"exercise-search-cell-div"} onClick={onExerciseSelected}>
                <div className="exercise-cell">
                    {getExerciseLeftDiv()}
                    {getExerciseCellRightDiv()}
                </div>
                <div className="exercise-separater"></div>
            </div>
        </>
    );
}

export default ExerciseQuickSearchResultCell;