import React from 'react';

function PhaseLinkImporterModal({setShowPhaseLinkImporterModalFlag, phaseLinkImporterInputData, setPhaseLinkImporterInputData, startPhaseImportViaLinks}) {
    function closeModal(){
        setShowPhaseLinkImporterModalFlag(false);
    }

    return (
        <div className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered">
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <a style={{fontSize: "21px", fontWeight: "500"}}>Paste entire phase links:</a>
                    <textarea cols="77" rows="14" style={{marginTop: "20px", marginBottom: "20px"}} value={phaseLinkImporterInputData} onChange={e => setPhaseLinkImporterInputData(e.target.value)} />
                    <button className="normal-button-system" onClick={startPhaseImportViaLinks} style={{width: "160px", margin: "auto", marginBottom: "12px"}}>Start Import</button>
                </div>
            </div>
        </div>
    );
}

export default PhaseLinkImporterModal;